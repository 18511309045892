import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Error404 } from "@/lib/pages/Error404";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import { BASE_FRONT_URL } from "../lib/api/global";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", [
        "index"
      ])),
      headerTheme: "light",
    },
  };
};

export default function Custom404() {
  const { t } = useTranslation("index", { keyPrefix: "meta" });
  return (
    <>
      <Head>
        <title>{t("notFound.title")}</title>
        <meta property="og:title" content={t("notFound.title")} />
        <meta property="og:keywords" content={t("metaKeywords")} />
        <meta property="og:image" content={"/images/og.png"} />
        <meta property="og:title" content={t("notFound.title")} />
        <meta property="og:keywords" content={t("metaKeywords")} />
        <meta property="og:type" content="article" />
        <meta property="og:image:width" content="510" />
        <meta property="og:site_name" content="Villacarte" />
        <meta property="og:url" content={BASE_FRONT_URL} />
      </Head>
      <Error404 />
    </>
  );
}
